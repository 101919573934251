<template>
  <form class="c-searchform" @submit.prevent="search" :class="{'is-searching':loading}">
    <button type="submit" class="c-searchform__submit"/>
    <input type="search" @focus="getFocus" ref="input" class="c-searchform__input" :placeholder="$t('search.input')" v-model="query"/>
    <button class="c-searchform__reset" @click.prevent="stop" v-if="query.length || (is_mobile&&focus)"/>
    <button class="c-searchform__vocal" :class="{'is-active':recognizing}" @click.prevent="toggleRecognition" v-if="recognizer"/>
  </form>
</template>

<script>

export default {
  data() {
    return {
      recognizer: false,
      recognizing: false,
      focus: false,
      transcription: ''
    }
  },
  computed:{
    loading(){
      return this.$store.state.loading;
    },
    is_mobile(){
      return window.innerWidth<1024
    },
    query: {
      get () {
        return this.$store.state.query
      },
      set (value) {
        this.$store.commit('query', value)
      }
    }
  },
  watch:{
    $route(to, from){
      if( from.name === 'search' && to.name === 'home' )
        this.reset(false);
    }
  },
  methods:{
    getFocus(){

      this.focus = true
      this.$emit('input-focus', true)
      this.$store.commit('searching', false)

      this.$router.push({ name: 'home'})
    },
    setFocus(){

      if( this.$refs.input )
        this.$refs.input.focus()
    },
    loseFocus(){

      if( this.$refs.input )
        this.$refs.input.blur()

      this.focus = false

      if( !this.is_mobile )
        this.$emit('input-focus', false)
    },
    search(){

      if( this.query.length < 3 )
        return;

      this.reset(true)
      this.$store.commit('searching', true)

      this.$router.push({ name: 'search', params: { term: this.query } })
    },
    initSpeechRecognition(){

      const speechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition

      if( !speechRecognition )
        return;

      this.recognizer = new speechRecognition();

      this.recognizer.addEventListener('result', this.getResult);
      this.recognizer.addEventListener('error', this.stopRecognition);
      this.recognizer.addEventListener('end', this.stopRecognition);
    },
    getResult(event){

      let transcription = '';

      for (let i = event.resultIndex, len = event.results.length; i < len; i++)
        transcription += event.results[i][0].transcript;

      if( transcription.length ){

        this.query = transcription;
        this.getFocus();
      }
    },
    toggleRecognition(){

      if( this.recognizing )
        this.stopRecognition()
      else
        this.startRecognition()
    },
    startRecognition(){

      try {

        this.recognizing = true;
        this.recognizer.start();

      } catch (error) {

        this.recognizing = false;
      }
    },
    stopRecognition(){

      this.recognizing = false;
      this.recognizer.stop();
    },
    stop(){

      this.reset(false)
      this.$emit('input-focus', false)

      this.$router.push({ name: 'home'})
    },
    reset(keep_query){

      this.loseFocus()

      if( !keep_query || typeof keep_query == 'undefined'){

        this.query = ""
        this.$store.commit('searching', false)
      }
    }
  },
  mounted() {

    this.initSpeechRecognition()

    this.emitter.on('searchFocus', this.setFocus)

    if( this.query )
      this.setFocus()
  },
  beforeUnmount() {

    this.recognizer.removeEventListener('result', this.getResult);
    this.recognizer.removeEventListener('error', this.stopRecognition);
    this.recognizer.removeEventListener('end', this.stopRecognition);
  }
}
</script>

<style lang="scss">
.c-searchform{
  height: 100%; background: $c-white; border-radius: $radius; display: flex; position: relative; z-index: 2;
  padding: 0 $space-s; pointer-events: all;
  @media #{$to-phone} {
    height: 5rem;
  }
  &__input{ height: 100%; border: 0; flex-grow: 1; background: none }
  &__submit{
    background: url("../assets/picto/magnifier.svg") no-repeat center; background-size: 50%; aspect-ratio: 1;
    height: 100%;
    @media #{$to-phone} { height: 80%; top: 10%; position: relative }
  }
  &__reset{
    background: url("../assets/picto/close.svg") no-repeat center; background-size: 50%; aspect-ratio: 1;
    height: 100%; cursor: pointer; position: relative;
    .is-searching &{ background-image: url("../assets/picto/loader.svg"); pointer-events: none }
  }
  &__vocal{
    background: url("../assets/picto/microphone.svg") no-repeat center; background-size: 50%; aspect-ratio: 1;
    height: 100%; cursor: pointer; position: relative;
    .is-searching &{ background-image: url("../assets/picto/loader.svg"); pointer-events: none }
    &.is-active{
      background: none; animation: pulse 3s $ease-in-out-quad infinite;
      &:after{
        content: ''; background: #cc0000; width: 1rem; height: 1rem; position: absolute;
        left: 50%; top: 50%; margin-left: -0.5rem; margin-top: -0.5rem; border-radius: 50%;
      }
      &:before{
        content: ''; outline: 1px solid #cc0000; outline-offset: 3px; width: 1rem; height: 1rem; position: absolute;
        left: 50%; top: 50%; margin-left: -0.5rem; margin-top: -0.5rem; border-radius: 50%;
      }
    }
  }
  &__reset+ &__vocal{ display: none }
}

@keyframes pulse {
  0%{ opacity: 1 }
  50%{ opacity: 0.3 }
  100%{ opacity: 1 }
}
</style>
