<template>
    <transition name="fade">
      <a class="c-error-toaster" v-html="message" v-if="message" @click="close"></a>
    </transition>
</template>

<script>
import axios from 'axios';

export default {
  data(){
    return {
      message:false
    }
  },
  methods:{
    close(){
      this.message=false
    }
  },
  mounted() {

    let self = this;

    axios.interceptors.response.use(
        function(response) {
          return response;
        },
        function(error) {
          if (error.response.status === 500) {
            self.message=error.response.data.message
            setTimeout(self.close, 3000)
          }
        }
    );
  }
}
</script>

<style lang="scss">
.c-error-toaster{
  position: absolute; bottom: $space-l; right: 7rem; z-index: 99999; display: flex; box-shadow: $box-shadow; min-width: 30rem;
  font-size: $font-xs; background: #fff; border-left: 3px solid #d41414; color: #d41414; padding: $space; cursor: pointer;
  @media #{$to-phone} { bottom: $space; right: $space; left: $space; min-width: auto }
}
</style>
