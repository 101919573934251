<template>
  <transition name="wait">
    <dialog class="c-popup" v-if="active">
      <div class="c-popup__inner">
        <router-view v-slot="{ Component }" name="popup">
            <component :is="Component" :key="$route.params.id"/>
        </router-view>
      </div>
    </dialog>
  </transition>
</template>

<script>

export default {
  computed: {
    active() {
      return this.$route.matched.length ? 'popup' in this.$route.matched[0].components : false;
    }
  },
}
</script>

<style lang="scss">
.c-popup{
  position: overlay(); z-index: 99999; display: block; border: 0; background: none; height: auto; padding: 0; width: auto;
  &__inner{
    height: 100%; position: relative;
  }
}
</style>
