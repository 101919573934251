// scripts/api.js
import axios from 'axios';
import md5 from 'md5';
import CryptoJS from 'crypto-js';


const search = async (query, limit) => {

  const api_url = process.env.VUE_APP_API_URL + '/api/map/search';

  if (query.replace(/ /g, '').length < 3) return [];

  const params = {
    query: query,
    limit: limit
  };

  trackEvent('search', {search_term:query});

  try {
    const res = await axios.get(api_url, { params: params });
    let result = [];

    res.data.forEach(feature => {
      let id = feature.id ? feature.id : md5(feature.label);
      result.push({ id: id, type: feature.type, label: feature.label, latLng: [feature.latitude, feature.longitude] });
    });

    if( !result.length )
      trackEvent('search_no_result', {search_term:query});

    return result;

  } catch (error) {

    return [];
  }
};

const getAllPoints = async () => {

  const api_url = process.env.VUE_APP_API_URL + '/api/map/all';

  try {

    const res = await axios.get(api_url);

    return res.data

  } catch (error) {

    return [];
  }
}

const degreesToRadians = (degrees) =>{

  return degrees * Math.PI / 180;
}

/**
 * Computes the distance between two geographical coordinates using the Haversine formula.
 *
 * @param {Array<number>} markerPosition - The [latitude, longitude] of the first position (e.g., a marker).
 * @param {Array<number>} currentPosition - The [latitude, longitude] of the second position (e.g., current location).
 * @return {number} The distance between the two positions in meters.
 */
const computeDistance = (markerPosition, currentPosition) => {

  const lat1 = markerPosition[0];
  const lon1 = markerPosition[1];

  const lat2 = currentPosition[0];
  const lon2 = currentPosition[1];

  const R = 6371e3; // mètres
  const latRad1 = degreesToRadians(lat1);
  const latRad2 = degreesToRadians(lat2);
  const latDiff = degreesToRadians(lat2 - lat1);
  const lonDiff = degreesToRadians(lon2 - lon1);

  const a = Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
      Math.cos(latRad1) * Math.cos(latRad2) *
      Math.sin(lonDiff / 2) * Math.sin(lonDiff / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // en mètres
}

const decode = (encryptedHex) => {

  // Extract the IV from the encrypted message
  const ivHex = encryptedHex.substring(0, 32);
  const iv = CryptoJS.enc.Hex.parse(ivHex);

  // Extract the ciphertext from the encrypted message
  const ciphertextBase64 = encryptedHex.substring(32);
  const ciphertext = CryptoJS.enc.Base64.parse(ciphertextBase64);

  // Create a key from the password
  const key = CryptoJS.SHA256(process.env.VUE_APP_SECRET_KEY);

  // Decrypt the ciphertext
  const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: ciphertext },
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
  );

  // Convert the decrypted message to a string (utf8)
  return decrypted.toString(CryptoJS.enc.Utf8);
}

const trackEvent = (event_name, params)=>{

  if( event_name.length )
    params.event = event_name

  console.log('Track event : '+JSON.stringify(params))

  window.dataLayer.push(params);
}

const formatMarker = (marker) => {

  let type = []

  if( marker.serviceDomicile )
    type.push('serviceDomicile')

  if( marker.correspondance )
    type.push('correspondance')

  if( marker.displayAdresse )
    type.push('boutique')

  return {
    shop_name: marker.nom,
    cordoniers: marker.reparationChaussures,
    retoucheurs: marker.reparationTextiles,
    collect_type: type.join('|'),
    shop_city: marker.ville,
    shop_zip: marker.codePostal
  }
}

export {search, getAllPoints, computeDistance, decode, trackEvent, formatMarker}