<template>
  <article class="c-store-details">
    <div class="c-store-details__content" v-if="marker">
      <h2 class="c-store-details__title" v-html="marker.nom"></h2>
      <div class="c-store-details__tags" v-drag>
        <a class="c-store-details__tag c-store-details__tag--shoes" v-if="marker.reparationChaussures">{{ $t('store.shoes') }}</a>
        <a class="c-store-details__tag c-store-details__tag--clothes" v-if="marker.reparationTextiles">{{ $t('store.clothes') }}</a>
        <a class="c-store-details__tag c-store-details__tag--mailbox" v-if="marker.correspondance">{{ $t('store.change') }}</a>
        <a class="c-store-details__tag c-store-details__tag--store" v-if="marker.displayAdresse">{{ $t('store.store') }}</a>
        <a class="c-store-details__tag c-store-details__tag--service" v-if="marker.serviceDomicile">{{ $t('store.service') }}</a>
      </div>
      <div class="c-store-details__data">
        <div class="c-store-details__info c-store-details__info--gps" v-if="marker.displayAdresse">
          <span></span>
          <p>
            {{ marker.adresse }}<br/>
            {{ marker.codePostal }} {{ marker.ville }}
          </p>
          <a @click="openNavigationSelector">{{ $t('store.go') }}</a>
        </div>
        <div class="c-store-details__distance" v-if="position.length&&!isOnlineSolution&&walkingTime<60">
          <span v-html="$t('store.distance', {duration:formatWalkingTime(walkingTime)})"></span>
        </div>
        <div class="c-store-details__info c-store-details__info--phone" v-if="marker.telephone.length">
          <span></span>
          <p>{{ marker.telephone }}</p>
          <a @click="trackContact('telephone')" :href="'tel:'+marker.telephone">{{ $t('store.call') }}</a>
        </div>
        <div class="c-store-details__info c-store-details__info--mail" v-if="marker.email.length">
          <span></span>
          <p>{{ decode(marker.email) }}</p>
          <a @click="trackContact('email')" :href="'mailto:'+decode(marker.email)" target="_blank">{{ $t('store.write') }}</a>
        </div>
        <div class="c-store-details__info c-store-details__info--website" v-if="marker.displaySiteInternet">
          <span></span>
          <p>{{ marker.siteInternet.replace('https://','') }}</p>
          <a @click="trackContact('siteInternet')" :href="marker.siteInternet" target="_blank">{{ $t('store.see') }}</a>
        </div>
      </div>
      <!--<div class="c-store-details__brands">
        <div class="c-store-details__brands-title">{{ $t('store.brands') }}</div>
        <div class="c-store-details__brands-logos">
          <img src="../assets/brands/zara.png"/>
          <img src="../assets/brands/zara.png"/>
          <img src="../assets/brands/zara.png"/>
        </div>
      </div>-->
      <div class="c-store-details__actions">
        <a class="c-store-details__cta c-store-details__cta--locate" @click="locate" v-if="marker.displayAdresse">{{ $t('store.locate') }}</a>
        <a class="c-store-details__cta c-store-details__cta--report" v-track-button="{location:'shop_view'}" href="https://faq.refashion.fr/hc/fr/requests/new" target="_blank">{{ $t('store.report') }}</a>
      </div>
    </div>
    <div class="c-store-details__content c-store-details__content--loading" v-else-if="loading">
      <p>{{ $t('store.loading') }}</p>
    </div>
    <div class="c-store-details__content c-store-details__content--404" v-else>
      <h2 class="c-store-details__title">{{ $t('store.error') }}</h2>
      <p>{{ $t('store.not_found') }}</p>
    </div>
  </article>
</template>

<script>
import {computeDistance, decode, formatMarker, trackEvent} from "@/scripts/api";

export default {
  methods:{
    decode,
    back(){
      this.$router.go(-1)
    },
    close(){
      this.$router.push({name:'home'})
    },
    locate(){
      this.emitter.emit('goTo', {latLng:this.marker.position, zoom:18, id:this.marker.id})
    },
    openNavigationSelector(){
      this.emitter.emit('openNavigationSelector', this.marker)
    },
    formatWalkingTime(timeInMinutes){
      if (timeInMinutes >= 60) {

        const hours = Math.floor(timeInMinutes / 60);
        const minutes = Math.round(timeInMinutes % 60);
        return `${hours} heure(s) et ${minutes} minute(s)`;

      } else {

        return `${Math.round(timeInMinutes)} minute(s)`;
      }
    },
    trackView(){
      trackEvent('view_shop', formatMarker(this.marker))
    },
    trackContact(event){

      trackEvent(event, {
        event: 'contact',
        contact_type: event,
        shop_name: this.marker.nom,
        shop_city: this.marker.ville,
        shop_zip: this.marker.codePostal,
        button_location: 'shop_view'
      })
    },
  },
  computed:{
    markers(){
      return this.$store.state.markers;
    },
    loading(){
      return this.$store.state.loading;
    },
    previousRoute(){
      return this.$store.state.previousRoute;
    },
    id(){
      return this.$route.params.id
    },
    marker(){
      return this.id in this.markers ? this.markers[this.id] : false
    },
    isOnlineSolution(){
      return !this.marker.displayAdresse
    },
    position(){
      return this.$store.state.position;
    },
    walkingTime(){
      
      const distance = computeDistance(this.marker.position, this.position);
      const walkingSpeedMetersPerMinute = 83.33;

      return distance / walkingSpeedMetersPerMinute;
    }
  },
  watch:{
    marker() {

      if( this.marker )
        this.trackView()
    }
  },
  mounted() {

    if( this.marker )
      this.trackView()
  }
}
</script>

<style lang="scss">
.c-store-details{
  display: flex; flex-flow: column; grid-gap: $space; position: relative; height: 100%;
  .c-sidebar__back ~ .c-sidebar__content &{ padding-top: 5rem }
  &__title{ @extend %title-section; padding-right: $space-l; word-break: break-word }
  &__distance{
    background: #FFC3C3; border-radius: $space-xs; padding: $space-xs $space-s; font-size: $font-xs;
    display: inline-flex; align-items: center;
    &:before{
      content: ''; display: inline-block; width: 1.2rem; height: 1.2rem; margin-right: $space;
      background: url("../assets/picto/person.svg") no-repeat center; background-size: contain;
    }
  }
  &__content{
    display: flex; flex-flow: column; grid-gap: $space-m; height: 100%;
    &--404{ align-items: center; justify-content: center; grid-gap: $space-s }
    &--loading{
      text-align: center; margin: $space 0; line-height: 1.6; padding: 0 $space; display: flex; flex-flow: column;
      align-items: center;
      &:before{
        content: ''; width: 6rem; height: 6rem; background: url("../assets/picto/loader.svg") no-repeat center;
        background-size: contain; display: inline-block;
      }
    }
  }
  &__tags{
    display: flex; grid-gap: $space; flex-shrink: 0;
    margin-left: -$space; padding-left: $space; width: calc(100% + 2*$space); padding-right: $space; user-select: none;
  }
  &__tag{
    display: flex; align-items: center;; grid-gap: $space-s; white-space: nowrap;
    &:before{
      content: ''; display: inline-block; width: 5rem; height: 5rem; border-radius: 50%;
      background: no-repeat center $c-white; background-size: 60%;
    }
    &--clothes:before{ background-image: url("../assets/picto/clothes.svg"); background-color: $c-theme-alt }
    &--shoes:before{ background-image: url("../assets/picto/shoes.svg"); background-color: $c-theme }
    &--store:before{ background-image: url("../assets/picto/store.svg") }
    &--mailbox:before{ background-image: url("../assets/picto/mailbox.svg") }
    &--service:before{ background-image: url("../assets/picto/service.svg") }
  }
  &__data{
    border-top: 1px solid $c-border; padding-top: $space; display: flex; flex-flow: column; grid-gap: $space;
    border-bottom: 1px solid $c-border; padding-bottom: $space;
    &:empty{ display: none }
  }
  &__info{
    display: flex; grid-gap: $space; align-items: flex-start;
    p{ padding-top: 4px; flex: 1; overflow: hidden; text-overflow: ellipsis }
    span{
      display: inline-block; width: 2.4rem; height: 2.4rem; flex-shrink: 0;
      background: no-repeat center; background-size: contain;
    }
    a{
      background: $c-white; padding: $space-xxs $space; border-radius: $radius; margin-left: auto; flex-shrink: 0;
      font-size: $font-xs; font-weight: 500; cursor: pointer; transition: allow(box-shadow);
      &:hover{ box-shadow: $box-shadow }
    }
    &--gps span{ background-image: url("../assets/picto/pin.svg") }
    &--phone span{ background-image: url("../assets/picto/phone.svg") }
    &--mail span{ background-image: url("../assets/picto/mail.svg") }
    &--website span{ background-image: url("../assets/picto/globe.svg") }
    &--website span{ background-image: url("../assets/picto/globe.svg") }
  }
  &__brands{
    display: flex; flex-flow: column; grid-gap: $space;
    &-title{ font-size: $font-xs; color: $c-grey }
    &-logos{
      display: flex; grid-gap: $space; overflow: hidden; margin-left: -$space; padding-left: $space;
      width: calc(100% + 2*$space); padding-right: $space; overflow: hidden;
      img{ height: 3rem }
    }
  }
  &__actions{
    display: flex; flex-flow: column; grid-gap: $space-s; margin-top: auto;
  }
  &__cta{
    display: flex; align-items: center; border-radius: $radius; padding: 15px $space; background: $c-white;
    justify-content: center; font-weight: 600; grid-gap: $space-s; cursor: pointer;
    &:before{ content: ''; display: inline-block; width: 2rem; height: 2rem; background: no-repeat center; background-size: contain }
    &--locate{
      background: $c-black; color: $c-white;
      transition: allow(background);
      &:hover{ background: #222 }
      &:before{ background-image: url("../assets/picto/pin-white.svg") }
    }
    &--report{
      transition: allow(box-shadow);
      &:hover{ box-shadow: $box-shadow }
      &:before{ background-image: url("../assets/picto/report.svg") }
    }
  }
}
</style>
