<template>
  <header class="c-topbar">
    <transition name="fade">
      <a class="c-topbar__back" v-if="back" @click="navigate"></a>
    </transition>
    <SearchBox v-if="!is_mobile"/>
    <SolutionFilters/>
  </header>
</template>

<script>
import SearchBox from "@/components/SearchBox.vue";
import SolutionFilters from "@/components/SolutionFilters.vue";
export default {
  components: {
    SearchBox,
    SolutionFilters
  },
  computed:{
    is_mobile(){
      return window.innerWidth<1024
    },
    previousRoute(){
      return this.$store.state.previousRoute;
    },
    back(){
      return this.$route.meta.back && this.is_mobile && this.previousRoute
    },
    currentRoute() {
      return this.$route.name
    }
  },
  methods:{
    navigate(){
      if( this.$route.meta.back === -1 )
        this.$router.go(-1)
      else
        this.$router.push({ name: this.$route.meta.back})
    }
  }
}
</script>

<style lang="scss">
.c-topbar{
  position: absolute; left: $space-m; top: $space; right: $space-m; height: 4rem; z-index: 10002; display: flex;
  font-size: $font-xs; grid-gap: $space-s;
  @media #{$to-phone} { right: $space; left: $space }
  &__back{
    width: 4rem; height: 4rem; display: inline-block;
    background: url("../assets/picto/arrow.svg") no-repeat center $c-white; background-size: 50%; z-index: 9998;
    border-radius: 50%; transform: scaleX(-1); box-shadow: $box-shadow; transition: allow(transform opacity);
    @media #{$from-phone} { display: none }
  }
  .c-solution-filters{
    cursor: auto;
    &__item{
      @media #{$to-phone} {
        display: none;
        &--online{
          display: inline-block; transition: allow(transform opacity);
        }
      }
    }
  }
}
</style>
