<template>
  <div class="c-online-solutions">
    <h2 class="c-online-solutions__title">{{ $t('online.title') }}</h2>
    <div class="c-online-solutions__items">
      <StoreSummary v-for="marker in solutions" :marker="marker" :key="marker.id"/>
    </div>
  </div>
</template>

<script>
import StoreSummary from "@/components/StoreSummary.vue";

export default {
  components: {
    StoreSummary
  },
  computed:{
    filteredMarkers(){
      return this.$store.state.filteredMarkers;
    },
    solutions(){
      return Object.values(this.filteredMarkers).filter(marker=>{
        return marker.correspondance || marker.typeEntiteLegale==='DIGITALE'
      })
    }
  }
}
</script>

<style lang="scss">
.c-online-solutions{
  display: flex; flex-flow: column; grid-gap: $space;
  &__items{
    display: flex; flex-flow: column; grid-gap: $space; padding-top: $space;
  }
  &__more{
    background: $c-white; padding: $space-xs; display: block; text-align: center; border-radius: $space; order: 99999;
    margin-top: $space; cursor: pointer;
  }
  &__title{ @extend %title-section }
  &__close{
    background: url("../assets/picto/close.svg") no-repeat center $c-white; border-radius: 50%;
    display: inline-block; width: 3rem; height: 3rem; background-size: 60%;
    position: absolute; right: $space-s; top: $space-s; cursor: pointer; z-index: 1;
  }
}
</style>
