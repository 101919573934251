<template>
  <div class="c-search-results">
    <h2 class="c-search-results__title">{{ $t('search.results') }}</h2>
    <div class="c-search-results__no-results" v-if="loading">
      <div class="c-search-results__message c-search-results__message--searching">{{ $t('search.searching') }}</div>
    </div>
    <div class="c-search-results__items" v-else-if="stores.length">
      <router-link v-track="{event:'solution_online_clicked', button_location:'search_results'}" v-if="onlineSolutions.length" class="c-search-results__online" :to="{name:'search-online'}">
        <span v-html="$t('search.online', {count:onlineSolutions.length})"></span>
        <span class="c-search-results__online-more">{{ $t('online.see') }}</span>
      </router-link>
      <StoreSummary :order="index" v-for="(marker, index) in stores" :marker="marker" :key="marker.id"/>
    </div>
    <div class="c-search-results__no-results" v-else>
      <div class="c-search-results__message">{{ $t('search.error') }}</div>
      <router-link v-track="{event:'solution_online_clicked', button_location:'search_results'}" v-if="onlineSolutions.length" class="c-search-results__cta c-search-results__cta--online" :to="{name:'search-online'}">{{ $t('search.see_online') }}</router-link>
      <a class="c-search-results__cta c-search-results__cta--search" v-track-button="{location:'search_results'}" @click="editSearch">{{ $t('search.edit') }}</a>
    </div>
  </div>
</template>

<script>
import StoreSummary from "@/components/StoreSummary.vue";
import {formatMarker, trackEvent} from "@/scripts/api";

export default {
  components: {
    StoreSummary
  },
  computed:{
    search_results(){
      return this.$store.state.search_results.filter((item)=>{
        return item.type === "BOUTIQUES" && item.id && item.id in this.markers
      }).map(this.getMarker);
    },
    onlineSolutions(){
      return this.search_results.filter(marker=>{
        return marker.typeEntiteLegale === 'DIGITALE'
      })
    },
    stores(){
      return this.search_results.filter(marker=>{
        return marker.typeEntiteLegale !== 'DIGITALE'
      })
    },
    markers(){
      return this.$store.state.markers;
    },
    loading(){
      return this.$store.state.loading;
    }
  },
  methods:{
    close(){
      this.$store.commit('searching', false)
      this.$router.push({name:'home'})
    },
    getMarker(result){
      return this.markers[result.id]
    },
    editSearch(){
      this.$router.push({name:'home'})
      this.emitter.emit('searchFocus');
    },
    trackView(){

      if( this.stores && this.stores.length )
        trackEvent('view_shop_list',{items: this.stores.map(formatMarker)})
    }
  },
  watch:{
    search_results() {
      this.trackView()
    }
  },
  mounted() {
    this.trackView()
  }
}
</script>

<style lang="scss">
.c-search-results{
  display: flex; flex-flow: column; grid-gap: $space;
  &__online{
    background: $c-black; color: $c-white; border-radius: $space-s; padding: $space; display: flex; grid-gap: $space;
    align-items: center; order: 2; cursor: pointer;
    &-more{
      display: inline-flex; align-items: center; grid-gap: $space-s; font-weight: 600;
      &:after{
        background: url("../assets/picto/arrow-white.svg") no-repeat center; background-size: cover; content: ''; width: 1em;
        height: 1em; display: inline-block;
      }
    }
    &~.c-store-summary--2{ border-top: 0; padding-top: 0 }
  }
  &__items{
    display: flex; flex-flow: column; grid-gap: $space; padding: $space 0;
  }
  .c-searchform{
    @media #{$from-phone} { display: none }
  }
  &__title{ @extend %title-section }
  &__cta{
    padding: $space; border-radius: $radius; background: $c-white; text-align: center; font-weight: 600;
    display: flex; align-items: center; grid-gap: $space-s; justify-content: center; cursor: pointer;
    &--online{ background: $c-black; color: $c-white; margin-bottom: $space-s }
    &--search{
      &:before{
        content: ''; width: 2rem; height: 2rem; background: url("../assets/picto/magnifier.svg") no-repeat center;
        background-size: contain; display: inline-block; margin-right: $space-s
      }
    }
  }
  &__message{
    text-align: center; margin: $space 0; line-height: 1.6; padding: 0 $space; display: flex; flex-flow: column;
    align-items: center;
    &:before{
      content: ''; width: 6rem; height: 6rem; background: url("../assets/picto/smiley.svg") no-repeat center;
      background-size: contain; display: inline-block; margin-bottom: $space
    }
    &--searching:before{
      background-image: url("../assets/picto/loader.svg")
    }
  }
}
</style>
