<template>
  <div class="c-solution-filters" v-drag="'mobile'">
    <ul class="c-solution-filters__items">
      <li v-for="id in activities" :key="id" class="c-solution-filters__item" :class="'c-solution-filters__item--'+id">
        <label>
          <input @change="track(id)" type="checkbox" v-model="form.activities" :value="id"/><span>{{ $t('filters.list.'+id) }}</span>
        </label>
      </li>
      <li v-for="id in filters" :key="id" class="c-solution-filters__item">
        <label>
          <input @change="track(id)" type="checkbox" v-model="form.filters" :value="id"/><span>{{ $t('filters.list.'+id) }}</span>
        </label>
      </li>
      <li class="c-solution-filters__item c-solution-filters__item--online">
        <router-link :to="{name:'online-solutions'}" v-track="{event:'solution_online_clicked', button_location:'map'}">
          <span>{{ $t('filters.online') }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {trackEvent} from "@/scripts/api";

export default {
  methods: {
    track(id){
      if( this.form.activities.indexOf(id) !== -1 || this.form.filters.indexOf(id) !== -1 )
        trackEvent('filter_selected', {filter_label:id})
    }
  },
  computed:{
    form: {
      get () {
        return this.$store.state.form
      },
      set (value) {
        this.$store.commit('form', value)
      }
    }
  },
  data() {
    return{
      activities: [
        "shoes",
        "clothes"
      ],
      filters: [
        "stores",
        "services",
        "change"
      ]
    }
  }
}
</script>

<style lang="scss">
.c-solution-filters{
  height: 100%; flex-shrink: 0;
  @media #{$to-phone} { margin-left: auto }
  &__items{
    height: 100%; display: flex; grid-gap: $space-s; align-items: center; font-size: $font-xs;
    @media (min-width: 1024px) and (max-width: 1280px) { grid-gap: $space-xxs }
  }
  &__item{
    position: relative; display: inline-block; cursor: pointer;
    &:hover span{
      box-shadow: $box-shadow; background: rgba(255,255,255,0.6);
    }
    input{ position: overlay(); opacity: 0; padding: 0; margin: 0 }
    input:checked+span{
      background: $c-white; padding-right: $space-m; font-weight: 500; color: inherit;
      &:after{ opacity: 1; transform: none }
    }
    &--shoes input:checked+span{ background: $c-theme }
    &--clothes input:checked+span{ background: $c-theme-alt }
    label{ cursor: pointer }
    span{
      border: 1px solid; border-radius: $radius; display: inline-flex; align-items: center; padding: $space-xxs $space;
      background: rgba(255,255,255,0.1); backdrop-filter: blur(2px); white-space: nowrap;
      transition: allow(background padding color box-shadow);
      @media (min-width: 1024px) and (max-width: 1280px) { padding: $space-xxs $space-s }
      &:after{
        content: '';background: url("../assets/picto/close.svg") no-repeat; background-size: contain; opacity: 0;
        width: 16px; height: 16px; display: inline-block; position: absolute; right: 1rem; top: 50%; margin-top: -8px;
        transition: allow(opacity transform); transform: scale(0);
      }
    }
    &--online{
      span{
        background: $c-black; color: $c-white; border-color: $c-black; font-weight: 500;
        @media #{$to-phone} { padding: $space-s $space }
      }
      &:hover span{ background: #222; border-color: #222 }
    }
  }
}
</style>
