import {trackEvent} from "@/scripts/api";

const drag = {
    mounted(el, binding) {

        if( binding.value === 'mobile' && window.innerWidth >= 1024 )
            return

        let lastMovementX = 0;
        let inertiaInterval = null;

        el.dragStart = (ev) => {

            el.classList.add('pressing');
            el.setPointerCapture(ev.pointerId);

            lastMovementX = 0;

            if (inertiaInterval)
                clearInterval(inertiaInterval);
        }

        el.dragEnd = (ev) => {

            el.classList.remove('pressing', 'dragging');
            el.releasePointerCapture(ev.pointerId)

            // Apply inertia
            const dampingFactor = 0.95;

            if (inertiaInterval)
                clearInterval(inertiaInterval);

            inertiaInterval = setInterval(() => {

                if (Math.abs(lastMovementX) < 0.5) {
                    clearInterval(inertiaInterval);
                    return;
                }

                el.scrollLeft -= lastMovementX;
                lastMovementX *= dampingFactor;

            }, 16);
        }

        el.drag = (ev) => {

            ev.preventDefault();
            ev.stopPropagation();

            el.classList.add('dragging');

            if (el.hasPointerCapture(ev.pointerId)) {

                el.scrollLeft -= ev.movementX;
                lastMovementX = ev.movementX; // Update the last movement
            }
        }

        el.classList.add('draggable');

        el.addEventListener("pointerdown", el.dragStart);
        el.addEventListener("pointerup", el.dragEnd);
        el.addEventListener("pointermove", el.drag);

        el.addEventListener("touchstart", el.dragStart);
        el.addEventListener("touchend", el.dragEnd);
        el.addEventListener("touchmove", el.drag, {passive: false});
    },
    unmounted(el) {

        el.removeEventListener("pointerdown", el.dragStart);
        el.removeEventListener("pointerup", el.dragEnd);
        el.removeEventListener("pointermove", el.drag);

        el.removeEventListener("touchstart", el.dragStart);
        el.removeEventListener("touchend", el.dragEnd);
        el.removeEventListener("touchmove", el.drag, {passive: false});
    }
};

const clickOutside = {
    mounted (el, binding) {

        el.clickOutsideEvent = (event)=> {

            if (!(el === event.target || el.contains(event.target)) && binding.value)
                binding.value(event);
        };

        document.body.addEventListener('mousedown', el.clickOutsideEvent)
    },
    unmounted(el) {

        document.body.removeEventListener('mousedown', el.clickOutsideEvent)
    }
}

const track = {
    mounted (el, binding) {

        el.classList.add('trackable')

        el.track = ()=> {
            trackEvent('', binding.value)
        };

        el.addEventListener('click', el.track)
    }
}
const trackButton = {
    mounted (el, binding) {

        el.classList.add('trackable')

        const params = {
            button_label : el.innerText,
            button_location : binding.value.location
        }

        el.track = ()=> {
            trackEvent('button_clicked', params)
        };

        el.addEventListener('click', el.track)
    }
}

export {drag, clickOutside, track, trackButton}
