<template>
  <article class="c-store-summary" :class="'c-store-summary--'+order" :style="'order:'+order">
    <h2 class="c-store-summary__title">{{ marker.nom }}</h2>
    <div class="c-store-summary__group">
      <p class="c-store-summary__addresss" v-if="marker.displayAdresse">{{ marker.adresse }}</p>
      <a class="c-store-summary__link" v-else-if="marker.displaySiteInternet" :href="marker.siteInternet" target="_blank">{{ marker.siteInternet.replace('https://','') }}</a>
      <div class="c-store-summary__distance" v-if="position.length&&!isOnlineSolution">{{ distance }}</div>
    </div>
    <router-link class="c-store-summary__cta" :to="{name:'store', params:{id:marker.id}}"></router-link>
    <div class="c-store-summary__tags">
      <a class="c-store-summary__tag c-store-summary__tag--shoes" :title="$t('store.shoes')" v-if="marker.reparationChaussures"></a>
      <a class="c-store-summary__tag c-store-summary__tag--clothes" :title="$t('store.clothes')" v-if="marker.reparationTextiles"></a>
      <a class="c-store-summary__tag c-store-summary__tag--mailbox" :title="$t('store.change')" v-if="marker.correspondance"></a>
      <a class="c-store-summary__tag c-store-summary__tag--store" :title="$t('store.store')" v-if="marker.displayAdresse"></a>
      <a class="c-store-summary__tag c-store-summary__tag--service" :title="$t('store.service')" v-if="marker.serviceDomicile"></a>
    </div>
  </article>
</template>

<script>
import {computeDistance} from "@/scripts/api";

export default {
  props:['order','marker'],
  computed:{
    isOnlineSolution(){
      return !this.marker.displayAdresse
    },
    position(){
      return this.$store.state.position;
    },
    distance() {

      const distance = computeDistance(this.marker.position, this.position); // en mètres

      return distance >= 1000 ? (distance / 1000).toFixed() + ' km' : distance.toFixed() + ' m';
    }
  }
}
</script>

<style lang="scss">
.c-store-summary{
  display: flex; flex-flow: column; grid-gap: $space-s; position: relative;
  &+&{ border-top:1px solid $c-border; padding-top: $space }
  &__title{ @extend %title-main; padding-right: $space-l }
  &__tags{ display: flex; grid-gap: $space-s }
  &__tag{
    width: 3.5rem; height: 3.5rem; background: $c-white no-repeat center; background-size: 60%; border-radius: 50%; display: inline-block;
    &--clothes{ background-image: url("../assets/picto/clothes.svg"); background-color: $c-theme-alt }
    &--shoes{ background-image: url("../assets/picto/shoes.svg"); background-color: $c-theme }
    &--store{ background-image: url("../assets/picto/store.svg") }
    &--mailbox{ background-image: url("../assets/picto/mailbox.svg") }
    &--service{ background-image: url("../assets/picto/service.svg") }
  }
  &__link{
    flex: 1; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: calc(100% - 8rem); cursor: pointer;
  }
  &__distance, &__more{
    display: inline-flex; align-items: center; justify-content: flex-end; position: absolute; grid-gap: $space-s;
    top: 0; right: 0;
  }
  &__addresss{ padding-right: 6rem }
  &__distance{
    color: $c-grey; font-size: $font-xs; margin-bottom: 2px;
  }
  &__more{
    &:after{
      background: url("../assets/picto/arrow.svg") no-repeat center; background-size: cover; content: ''; width: 1em;
      height: 1em; display: inline-block;
    }
  }
  &__group{ position: relative }
  &__cta{
    position: overlay(); cursor: pointer;
  }
}
</style>
