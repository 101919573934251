<template>
  <section class="c-welcome">
    <div class="c-welcome__intro">
      <h2 class="c-welcome__title">{{ $t('home.title') }}</h2>
      <p class="c-welcome__description">{{ $t('home.description') }}</p>
    </div>
    <div class="c-welcome__map">
      <span class="c-welcome__map-border c-welcome__map-border--left"></span>
      <router-link :to="{name:'home'}" @click="detectPosition" v-track-button="{location:'welcome'}">{{ $t('home.search') }}</router-link>
      <span class="c-welcome__map-border c-welcome__map-border--right"></span>
    </div>
    <div class="c-welcome__filter">
      <router-link :to="{name:'filters'}" v-track-button="{location:'welcome'}">{{ $t('home.filter') }}</router-link>
    </div>
  </section>
</template>

<script>

export default {
  methods:{
    detectPosition(){
      this.emitter.emit("detectPosition");
    }
  }
}
</script>

<style lang="scss">
.c-welcome{
  display: flex; flex-flow: column; height: 100%;
  &__title{ @extend %title-hero; text-align: center; margin-bottom: $space }
  &__description{ text-align: center; font-size: $font-m }
  a{
    display: block; text-align: center; padding: $space $space-l; font-weight: 500; border-radius: $space-xl; line-height: 1;
    position: relative; padding-left: $space-xl; width: 28rem;
    &:before{
      position: absolute; left: $space; top: 50%; content: ''; width: 1.6rem; height: 1.6rem; background: no-repeat center; background-size: contain;
      transform: translateY(-50%);
    }
  }
  &__intro{
    padding: $space-l; background: $c-background; transition: allow(transform);
    .wait-leave-active &{ transform: translateY(-100%) }
  }
  &__map{
    height: 100%; display: flex; align-items: center; width: 100%; justify-content: space-between;
    &:after, &:before{
      width: $space; top: 0; height: 100%; content: ''; display: block; background: $c-background; position: absolute;
      transition: allow(transform);
    }
    &:after{
      left: 0;
      .wait-leave-active &{ transform: translateX(-100%) }
    }
    &:before{
      right: 0;
      .wait-leave-active &{ transform: translateX(100%) }
    }
    &-border{
      display: block; width: $space; height: 100%; position: relative;
      &:after, &:before{
        width: $space; height: $space; content: ''; display: block; background: transparent; position: absolute;
        .wait-leave-active &{ opacity: 0 }
      }
      &--right{
        right: 0;
        &:after{ top: 0; right: $space; border-top-right-radius: $space; box-shadow: 2rem -2rem 0 $space $c-background; }
        &:before{ bottom: 0; right: $space; border-bottom-right-radius: $space; box-shadow: 2rem 2rem 0 $space $c-background; }
      }
      &--left{
        left: 0;
        &:after{ top: 0; left: $space; border-top-left-radius: $space; box-shadow: -2rem -2rem 0 $space $c-background; }
        &:before{ bottom: 0; left: $space; border-bottom-left-radius: $space; box-shadow: -2rem 2rem 0 $space $c-background; }
      }
    }
    a{
      background: $c-theme; transition: allow(transform opacity);
      &:before{ background-image: url("../assets/picto/magnifier.svg") }
      .wait-leave-active &{ transform: scale(1.1); opacity: 0 }
    }
  }
  &__filter{
    padding: $space-l; background: $c-background; transition: allow(transform); display: flex; justify-content: center;
    .wait-leave-active &{ transform: translateY(100%) }
    a{
      background: $c-theme-alt;
      &:before{ background-image: url("../assets/picto/filter.svg") }
    }
  }
}
</style>
