<template>
  <WorldMap/>
  <TopBar/>
  <SideBar/>
  <PopupDialog/>
  <NavigationSelector/>
  <ErrorToaster/>
</template>

<script>

import Bowser from "bowser";
const browser = Bowser.getParser(window.navigator.userAgent);

import WorldMap from './components/WorldMap.vue'
import TopBar from "@/components/TopBar.vue";
import SideBar from "@/components/SideBar.vue";
import PopupDialog from "@/components/PopupDialog.vue";
import NavigationSelector from "@/components/NavigationSelector.vue";

import {getAllPoints, search, trackEvent} from "@/scripts/api";

import markersFallback from "../static/markers.json"
import ErrorToaster from "@/components/ErrorToaster.vue";

export default {
  name: 'App',
  components: {
    ErrorToaster,
    PopupDialog,
    NavigationSelector,
    SideBar,
    TopBar,
    WorldMap
  },
  data() {
    return {
      version: 1,
      autocomplete_timeout: false,
      api_url: process.env.VUE_APP_API_URL+'/api/public/carte/all'
    }
  },
  computed:{
    query(){
      return this.$store.state.query
    },
    loading(){
      return this.$store.state.loading
    }
  },
  watch:{
    $route(to, from){

      this.$store.commit('previousRoute', from.name)
    },
    query(){
      clearTimeout(this.autocomplete_timeout)
      this.autocomplete_timeout = setTimeout(this.searchAutocomplete,400)
    }
  },
  methods:{
    addBrowserClasses(){

      if( !browser.satisfies({"internet explorer": ">11", safari: '>=13', chrome: ">=85", firefox: ">=83", edge: ">=84"}) ){

        document.body.classList.add('unsupported-browser')
      }
      else{

        document.body.classList.add(browser.getPlatformType(true))
        document.body.classList.add(browser.getOSName(true))
      }
    },
    searchAutocomplete(){

      this.$store.commit('loading', true);

      this.$nextTick(()=>{

        search(this.query, 20).then((search_results)=>{

          this.$store.commit('search_results', search_results);
        });
      })
    },
    catchResize(){

      document.documentElement.style.setProperty('--app-offset', `${window.visualViewport.offsetTop}px`);
      document.documentElement.style.setProperty('--app-height', `${window.visualViewport.height}px`);
    },
    async getMarkers(){

      if( this.loading )
        return;

      this.$store.commit('loading', true);

      let markers = await getAllPoints();

      if( !Object.values(markers).length )
        markers = markersFallback

      this.$store.commit('markers', markers)
    },
    getQueryFromUrl(){

      if( 'term' in this.$route.params && this.$route.params.term )
        this.$store.commit('query', this.$route.params.term);
      else
        this.$store.commit('loading', false);

      if( this.$route.name === 'search' || this.$route.name === 'search-online' )
        this.$store.commit('searching', true);
    }
  },
  mounted(){

    document.body.classList.remove('loading');
    document.body.classList.add('loaded');

    document.documentElement.style.setProperty('--app-init-height', `${window.visualViewport.height}px`);

    trackEvent('page_view',{
      'page_location': document.location.pathname,
      'page_title': document.title,
      'page_language': document.documentElement.lang
    });

this.$store.dispatch('load');
    this.$router.isReady().then(this.getQueryFromUrl)

    this.catchResize();
    this.getMarkers();

    this.$nextTick(this.catchResize)
  },
  created() {

    window.addEventListener('resize', this.catchResize);
    window.visualViewport.addEventListener('resize', this.catchResize);

    this.addBrowserClasses();
  }
}
</script>

<style lang="scss">

@import "styles/reset";

/*
* Core
*/

@import "styles/core/animations";
@import "styles/core/grid";
@import "styles/core/gutemberg";
@import "styles/core/has-sizer";
@import "styles/core/layout";
@import "styles/core/responsive";
@import "styles/core/text";
@import "styles/core/theme";
@import "styles/core/unsupported-browsers";

/*
* Misc
*/

@import "styles/misc/fonts";
@import "styles/misc/keyframes";
@import "styles/misc/media-queries";

/*
* Vendors
*/

@import "styles/vendor/vuejs";

</style>
